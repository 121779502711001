import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { tableAnatomy as parts } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(['container', 'header', ...parts.keys]);

const baseStyle = definePartsStyle(({ theme }) => {
  return {
    container: {
      borderRadius: 'lg',
      ...theme.styleTokens.defaultBorder,
      '> table': {
        border: 'none',
      },
    },
    header: {
      borderTopLeftRadius: 'lg',
      borderTopRightRadius: 'lg',
      ...theme.styleTokens.defaultBorder,
      borderBottom: 'none',
      display: 'flex',
      flexDirection: { base: 'column', md: 'row' },
      gap: 5,
      justifyContent: { md: 'space-between' },
      left: 0,
      px: { base: 4, md: 6 },
      py: { base: 4, md: 5 },
      whiteSpace: 'nowrap',
      '+ .bam-table-container': {
        borderTopColor: 'neutral.200',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        minHeight: 6,
      },
      '+ .bam-table-container > table': {
        th: {
          '&:first-of-type': {
            borderTopLeftRadius: 0,
          },
          '&:last-of-type': {
            borderTopRightRadius: 0,
          },
        },
      },
    },
    table: {
      borderCollapse: 'unset',
      borderRadius: 'lg',
    },
    td: {
      whiteSpace: 'normal',
    },
    th: {
      letterSpacing: 'normal',
      textTransform: 'none',
      '&:first-of-type': {
        borderTopLeftRadius: 'lg',
      },
      '&:last-of-type': {
        borderTopRightRadius: 'lg',
      },
    },
  };
});

const simpleVariant = definePartsStyle(({ theme }) => {
  return {
    table: {
      border: '1px solid',
      borderColor: 'neutral.200',
      borderSpacing: 0,
    },
    tbody: {
      tr: {
        '&:last-of-type': {
          td: {
            borderBottomWidth: 0,
          },
        },
      },
    },
    td: {
      borderColor: 'transparent',
      borderBottomColor: 'neutral.200',
      color: 'text.secondary',
      lineHeight: 'base',
      px: { base: 4, md: 6 },
      py: { base: 3, md: 4 },
      ...theme.textStyles.labelMediumRegular,
      a: {
        color: 'text.secondary',
        _hover: {
          color: 'text.secondary',
          textDecoration: 'none',
        },
        _visited: {
          color: 'text.secondary',
        },
      },
    },
    tfoot: {
      borderCollapse: 'collapse',
      tr: {
        td: {
          borderTop: '1px solid',
          borderColor: 'neutral.200',
          borderBottom: 0,
          borderLeft: 0,
          borderRight: 0,
          p: 0,
        },
      },
    },
    th: {
      bg: 'neutral.50',
      borderColor: 'transparent',
      borderBottomColor: 'neutral.200',
      color: 'text.secondary',
      lineHeight: 'base',
      px: { base: 4, md: 6 },
      py: { base: 3, md: 4 },
      ...theme.textStyles.labelSmallMedium,
    },
  };
});

const variants = {
  simple: simpleVariant,
};

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps: {
    variant: 'simple',
  },
});
