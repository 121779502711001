import { messagesInitialState, messagesReducer } from './messages';
import brandProfileReducer, {
  brandProfileInitialState as brandProfile,
} from 'reducers/brandProfile';
import brandReducer from './brand';
import globalReducer from './global';
import integrationsReducer from './integrations';
import sessionReducer, { sessionInitialState as session } from './session';

export const client_details = {
  'categories[]': [],
  pricePoint: '',
  competitors: '',
  marketSales: '',
  termLength: '',
  'interestedMarkets[]': [],
  launchDate: '',
  'objectives[]': [],
  'commerceChannels[]': [],
  retailer: '',
  retailSales: '',
  task_progress: {
    orientation: false,
    onboarding: false,
    operational: false,
    ongoing: false,
  },
  channelSales: '',
  channelConvRate: '',
  netSalesLast12: '',
  netSalesNext12: '',
  profitMargin: '',
  orderValue: '',
  contract_signed_status: undefined,
  survey_status: undefined,
};

/**
 * Remember: client shape is
 *
 *   ...
 *   client: {
 *     brand: {
 *       data: {
 *         adapters:...,
 *         brand: {
 *           brand_details: ...
 *         },
 *         ...
 *       },
 *       error: ...,
 *       isError: ...,
 * .     isFetched: ...,
 * .     isFetching: ...,
 *     },
 *   }
 */
export const initialState = {
  messages: messagesInitialState,
  session,
  global: {
    helptip: {
      tip: '',
      position: {},
    },
    loading: false,
    open_sidebar: false,
  },
  integrations: {},
  brand: {
    index: [],
    show: {
      adapters: [],
      client_details: client_details,
      box_link: '',
      name: '',
      website: '',
      id: '',
    },
    history: [],
    pageLength: 10,
    pagination: {
      current_page: 1,
      limit: 10,
      offset: 0,
      count: 0,
    },
    sort: {
      order: 'DESC',
      orderBy: 'created_at',
    },
  },
  brandProfile,
};

export const Reducer = (
  state: typeof initialState,
  action: any
): typeof initialState => {
  if (['LOGOUT'].includes(action.type)) {
    return initialState;
  }

  if (window.__leapDebug === 'INFO') {
    // eslint-disable-next-line no-console
    console.log(action);
  }

  const newState = {
    // auth: authReducer(state.auth, action),
    brand: brandReducer(state.brand, action),
    brandProfile: brandProfileReducer(state.brandProfile, action),
    global: globalReducer(state.global, action),
    integrations: integrationsReducer(state.integrations, action),
    messages: messagesReducer(state.messages, action),
    session: sessionReducer(state.session, action),
    t: undefined,
  };

  return newState;
};

export default Reducer;
