import { AdminIndexRoute } from './admin-index-route';
import { AppInfo } from 'pages/admin/app-info';
import { ProtectedRoute } from '../ProtectedRoute';
import { Route } from 'react-router-dom';
import { RouteLoadingFallback } from 'routes/route-loading-fallback';
import { Routes } from '../Routes';
import React, { lazy } from 'react';

const AdminAccountRouter = lazy(() => import('./admin-account-router'));
const AdminAnalyticsRouter = lazy(() => import('./admin-analytics-router'));
const AdminBrandsRouter = lazy(() => import('./admin-brands-router'));
const AdminInsightsRouter = lazy(() => import('./admin-insights-router'));
const AdminUsersRouter = lazy(() => import('./admin-users-router'));
const NotificationsRouter = lazy(() => import('routes/notifications/index'));
const SpacesRouter = lazy(() => import('./spaces-router'));
const StoresRouter = lazy(() => import('./stores-router'));
const LeapbotAdminRouter = lazy(() => import('./leapbot-admin-router'));

export const AdminRouter: React.FC = () => {
  return (
    <RouteLoadingFallback>
      <Routes>
        <Route path="account/*" element={<AdminAccountRouter />} />
        <Route path="analytics/*" element={<AdminAnalyticsRouter />} />
        <Route path="*" element={<ProtectedRoute flag="appInfoPage" />}>
          <Route path="app_info/*" element={<AppInfo />} />
        </Route>
        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ action: 'get', subject: 'admin:brands' }}
            />
          }
        >
          <Route path="brands/*" element={<AdminBrandsRouter />} />
        </Route>
        <Route path="insights/*" element={<AdminInsightsRouter />} />
        <Route path="*" element={<ProtectedRoute flag="leapbotAdmin" />}>
          <Route path="leapbotAdmin/*" element={<LeapbotAdminRouter />} />
        </Route>
        <Route path="notifications/*" element={<NotificationsRouter />} />
        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ action: 'get', subject: 'admin:locations' }}
            />
          }
        >
          <Route path="spaces/*" element={<SpacesRouter />} />
        </Route>
        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ action: 'get', subject: 'admin:stores' }}
            />
          }
        >
          <Route path="stores/*" element={<StoresRouter />} />
        </Route>
        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ action: 'get', subject: 'admin:users' }}
            />
          }
        >
          <Route path="users/*" element={<AdminUsersRouter />} />
        </Route>
        <Route
          path="*"
          element={
            <ProtectedRoute
              protect={{ action: 'get', subject: 'admin:brands' }}
            />
          }
        >
          <Route index element={<AdminIndexRoute />} />
        </Route>
      </Routes>
    </RouteLoadingFallback>
  );
};
