import { types } from 'actions';

export const sessionInitialState = {
  'https://leapinc.co/intercom_hmac': undefined,
  'https://leapinc.co/user_id': undefined,
  accessToken: undefined,
  allowed_role_changes: undefined,
  aud: undefined,
  azp: undefined,
  brands: undefined,
  display_name: undefined,
  email_verified: undefined,
  email: undefined,
  exp: undefined,
  expiresIn: undefined,
  full_name: undefined,
  gty: undefined,
  iat: undefined,
  idToken: undefined,
  iss: undefined,
  last_login: undefined,
  permissions: undefined,
  picture: undefined,
  roles: undefined,
  scope: undefined,
  sub: undefined,
  token: undefined,
  tokenType: undefined,
  unread_in_app: undefined,
  updated_at: undefined,
  user_hash: undefined,
  user_role_grouping: undefined,
};

const Session = (state = sessionInitialState, action) => {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        ...action.data,
        token: action.data.accessToken,
        user_hash: action.data['https://leapinc.co/intercom_hmac'],
      };
    case types.CLEAN:
    case types.SET_REACT_ERROR:
      return {
        ability: {
          can: () => {
            return false;
          },
        },
      };
    case types.UPDATE_SESSION_USER_SUCCESS:
      return {
        ...state,
        ...action.data,
        // name: action.data.full_name,
        // nickname: action.data.display_name,
      };
    default:
      return state;
  }
};

export default Session;
