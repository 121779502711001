import { Alert } from '../alert';
import { Box, useToast as useChakraToast } from '@chakra-ui/react';
import { CloseButton } from '../../other';
import { Subheading, Text } from '../../typography';
import { type ToastMessage } from './toaster.types';
import { types } from 'actions';
import { useStateValue } from 'providers';
import React from 'react';

export function useToaster() {
  const [, dispatch] = useStateValue();
  const toast = useChakraToast();

  return React.useCallback(
    (toastMessage: ToastMessage) => {
      const effectiveStatus =
        toastMessage.type === 'danger' ? 'error' : toastMessage.type;

      toast({
        duration: toastMessage.ephemeral ? toastMessage.ephemeral : null,
        id: toastMessage.id,
        isClosable: true,
        onCloseComplete: () => {
          dispatch({
            type: types.REMOVE_MESSAGE,
            data: toastMessage,
          });
        },
        position: 'bottom',
        render: () => (
          <Alert maxW="24rem" status={effectiveStatus}>
            <Box>
              <Subheading as="h4">{toastMessage.title}</Subheading>
              <Text mb={0}>{toastMessage.message}</Text>
            </Box>

            <CloseButton
              onClick={() => {
                if (toastMessage.id) {
                  toast.close(toastMessage.id);
                }
              }}
            />
          </Alert>
        ),
      });
    },
    [toast, dispatch]
  );
}
