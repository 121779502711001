import { AlertIcon } from 'bam/components/feedback/alert/alert-icon';
import { AlertProvider } from 'bam/components/feedback/alert/alert-context';
import {
  Alert as ChakraAlert,
  AlertProps as ChakraAlertProps,
  forwardRef,
} from '@chakra-ui/react';
import React from 'react';

export type AlertType = 'info' | 'error' | 'warning' | 'success';

export interface AlertProps
  extends Omit<ChakraAlertProps, 'status' | 'colorScheme' | 'variant'> {
  /**
   * Alert color, maps to Chakra status of Alert component
   * info = primary color, all others are the same
   * @default info
   */
  status?: AlertType;

  /**
   * @default subtle
   */
  variant?: 'subtle' | 'solid';

  /**
   * adds role="alert" for screen readers and a11y
   * @default true
   */
  addRole?: boolean;
  children?: React.ReactNode;
}

/**
 * Alert wrapper for the [Chakra Alert](https://chakra-ui.com/docs/components/alert/usage) component.
 * - `AlertDescription` is the only sub-component at this time
 * - Alert `status` chooses the icon and the color scheme, and it is always shown
 * - the `loading` status is not included at this time, but can easily be added later when the need arises
 * - AlertTitle, AlertActions, and closeButton are planned for the future but not needed at this time
 *
 * ```tsx
 * <Alert status="info">
 *   <AlertDescription>
 *    The quick brown fox jumped over the log!
 *   </AlertDescription>
 * </Alert>
 * ```
 */
export const Alert = forwardRef<AlertProps, 'div'>(
  (
    {
      status: propsStatus = 'info',
      addRole = true,
      children,
      variant = 'subtle',
      ...rest
    },
    ref
  ) => {
    const status: Exclude<ChakraAlertProps['status'], 'loading'> = propsStatus;
    return (
      <AlertProvider value={{ status }}>
        <ChakraAlert
          {...rest}
          ref={ref}
          addRole={addRole}
          alignItems="flex-start"
          colorScheme={status}
          gap="2"
          status={status}
          variant={variant}
        >
          <AlertIcon />
          {children}
        </ChakraAlert>
      </AlertProvider>
    );
  }
);
