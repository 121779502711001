import {
  Box,
  forwardRef,
  useBreakpointValue,
  useStyleConfig,
} from '@chakra-ui/react';
import { FeaturedIconProps, FeaturedIconSizes } from './featured-icon.types';
import React from 'react';

const iconSizesMap: Record<FeaturedIconSizes, string> = {
  xs: '3',
  sm: '4',
  md: '5',
  lg: '6',
  xl: '7',
};

/**
 * FeaturedIcon displays an icon in a decorative color circle, with various colors and styles. It extends box
 * for flexibility.
 *
 * ```tsx
 * <FeaturedIcon icon={<Eye />} size="md" variant="lightCircleOutlined" color="primary" />
 * ```
 */
export const FeaturedIcon = forwardRef<FeaturedIconProps, 'div'>(
  (
    {
      icon,
      as,
      color: colorScheme = 'primary',
      variant = 'lightCircle',
      size = 'lg',
      ...rest
    },
    ref
  ) => {
    const styles = useStyleConfig('FeaturedIcon', {
      variant,
      colorScheme,
      size,
    });

    const breakpoint = useBreakpointValue(
      size && typeof size !== 'string' ? size : { base: size }
    );

    const featuredIcon = React.cloneElement(icon, {
      boxSize: iconSizesMap[breakpoint ?? 'lg'],
    });
    return (
      <Box {...rest} __css={styles} as={as} ref={ref}>
        {featuredIcon}
      </Box>
    );
  }
);
