import { Navigate, Route } from 'react-router-dom';
import { ProtectedRoute } from 'routes/ProtectedRoute';
import { RouteLoadingFallback } from 'routes/route-loading-fallback';
import { Routes } from 'routes/Routes';
import { lazy } from 'react';
import { useFlags } from 'showroom/use-flags';
import Adapters from 'pages/brand/adapters/adapters';

const AccountRouter = lazy(() => import('./brand-account-router'));
const AnalyticsRouter = lazy(
  () => import('routes/brand/brand-analytics-router')
);
const BrandProfileRouter = lazy(() => import('./brand-profile-router'));
const BrandTasksRouter = lazy(() => import('routes/brand/brand-tasks-router'));
const DataPlus = lazy(() => import('pages/brand/data-plus/brand-data-plus'));
const InsightsRouter = lazy(() => import('routes/brand/brand-insights-router'));
const MarketsRouter = lazy(() => import('routes/brand/markets-router'));
const NotificationsRouter = lazy(() => import('routes/notifications/index'));
const OmnichannelFitness = lazy(
  () => import('pages/brand/OmnichannelFitness/Main')
);
const SpacesRouter = lazy(() => import('routes/brand/spaces-router'));
const StoresRouter = lazy(() => import('routes/brand/brand-stores-router'));
const UsersRouter = lazy(() => import('routes/brand/brand-users-router'));

export const BrandRouter = () => {
  const flags = useFlags();

  return (
    <RouteLoadingFallback>
      <Routes>
        <Route
          path="*"
          element={
            <ProtectedRoute protect={{ subject: 'brand', action: 'get' }} />
          }
        >
          {flags.analyticsPages &&
          Array.isArray(flags.analyticsPages) &&
          flags.analyticsPages.length > 0 ? (
            <Route path="analytics">
              <Route path="insights/*" element={<InsightsRouter />} />

              <Route
                path="*"
                element={
                  <ProtectedRoute
                    protect={{ subject: 'brand:analytics', action: 'get' }}
                  />
                }
              >
                <Route path="*" element={<AnalyticsRouter />} />
              </Route>
            </Route>
          ) : null}

          {/*
            This used to point to the 'mega card' which was never used.
            Redirect to BP just in case.
          */}
          <Route
            path="onboarding"
            element={<Navigate to="/brand/profile" state={{ replace: true }} />}
          />

          <Route path="omnichannel_fitness">
            <Route
              path="*"
              element={
                <ProtectedRoute
                  protect={{ subject: 'brand:analytics', action: 'get' }}
                />
              }
            >
              <Route path="*" element={<OmnichannelFitness />} />
            </Route>
          </Route>

          <Route path="account/*" element={<AccountRouter />} />
        </Route>

        <Route path="adapters" element={<Adapters />} />
        {flags.dataPlus === 'enabled' ? (
          <Route path="data_plus" element={<DataPlus />} />
        ) : null}
        <Route path="markets/*" element={<MarketsRouter />} />
        <Route path="notifications/*" element={<NotificationsRouter />} />
        <Route path="profile/*" element={<BrandProfileRouter />} />
        <Route path="spaces/*" element={<SpacesRouter />} />
        <Route path="stores/*" element={<StoresRouter />} />
        <Route path="tasks/*" element={<BrandTasksRouter />} />
        <Route path="users/*" element={<UsersRouter />} />
      </Routes>
    </RouteLoadingFallback>
  );
};
