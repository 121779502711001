export const types = {
  //brand
  CLEAN_BRAND_HISTORY: 'CLEAN_BRAND_HISTORY',
  CREATE_BRAND_SUCCESS: 'CREATE_BRAND_SUCCESS',
  DELETE_BRAND_ASSET_FAILURE: 'DELETE_BRAND_ASSET_FAILURE',
  DELETE_BRAND_ASSET_SUCCESS: 'DELETE_BRAND_ASSET_SUCCESS',
  FETCH_BRAND_SUCCESS: 'FETCH_BRAND_SUCCESS',
  GET_BRAND_SUCCESS: 'GET_BRAND_SUCCESS',
  GET_BRAND_STORES_SUCCESS: 'GET_BRAND_STORES_SUCCESS',
  GET_BRAND_USERS_SUCCESS: 'GET_BRAND_USERS_SUCCESS',
  UPDATE_BRAND_SUCCESS: 'UPDATE_BRAND_SUCCESS',
  SET_BRAND_PAGE_LENGTH: 'SET_BRAND_PAGE_LENGTH',
  SET_BRAND_PAGINATION_LIMIT: 'SET_BRAND_PAGINATION_LIMIT',
  SET_BRAND_PAGINATION_CURRENT_PAGE: 'SET_BRAND_PAGINATION_CURRENT_PAGE',
  SET_BRAND_SORT: 'SET_BRAND_SORT',
  ONBOARD_BRAND_SUCCESS: 'ONBOARD_BRAND_SUCCESS',
  ONBOARD_BRAND: 'ONBOARD_BRAND',
  SYNC_BRAND_INVENTORY: 'SYNC_BRAND_INVENTORY',
  SYNC_BRAND_INVENTORY_SUCCESS: 'SYNC_BRAND_INVENTORY_SUCCESS',
  CLEAR_BRAND_INDEX: 'CLEAR_BRAND_INDEX',
  // "create new store" modal
  GET_CREATE_NEW_STORE_DATA_IN_PROGRESS:
    'GET_CREATE_NEW_STORE_DATA_IN_PROGRESS',
  GET_CREATE_NEW_STORE_DATA_SUCCESS: 'GET_CREATE_NEW_STORE_DATA_SUCCESS',
  GET_CREATE_NEW_STORE_DATA_FAILED: 'GET_CREATE_NEW_STORE_DATA_FAILED',
  // Model inputs
  GET_CLIENT_MODEL_INPUTS_FAILURE: 'GET_CLIENT_MODEL_INPUTS_FAILURE',
  SET_CLIENT_MODEL_INPUTS: 'SET_CLIENT_MODEL_INPUTS',
  //error
  REMOVE_ERRORS: 'REMOVE_ERRORS',
  //global
  CLEAR_LOADING: 'CLEAR_LOADING',
  CLOSE_SIDEBAR: 'CLOSE_SIDEBAR',
  LOADING: 'LOADING',
  OPEN_SIDEBAR: 'OPEN_SIDEBAR',
  //integrations
  LAUNCH_EXCEL: 'LAUNCH_EXCEL',
  LAUNCH_UPLOAD: 'LAUNCH_UPLOAD',
  LAUNCH_SHOPIFY: 'LAUNCH_SHOPIFY',
  //messages
  ADD_MESSAGE: 'ADD_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE',
  REMOVE_ALL_MESSAGES: 'REMOVE_ALL_MESSAGES',
  //survey
  CONNECT_SHOPIFY_REDIRECT: 'CONNECT_SHOPIFY_REDIRECT',
  CONNECT_SHOPIFY_FAILURE: 'CONNECT_SHOPIFY_FAILURE',
  //session
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  CLEAN: 'CLEAN',
  TOGGLE_HELP_MODAL: 'TOGGLE_HELP_MODAL',
  SET_REACT_ERROR: 'SET_REACT_ERROR',
  UPDATE_SESSION_USER_SUCCESS: 'UPDATE_SESSION_USER_SUCCESS',
};
