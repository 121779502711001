import { AdminPresentRouter } from './admin/admin-present-router';
import { AdminRouter } from './admin';
import { AuthenticatedIndexRoute } from './authenticated-index-route';
import { BrandRouter } from './brand';
import { CorporateRouter } from './corporate';
import { HelpShopify } from 'pages/help/help-shopify';
import { Logout } from 'pages/auth/logout';
import { NotFound } from 'pages/error/not-found';
import { PresentTemplate, PrimaryTemplate } from 'bam';
import { RequireAuthentication } from './require-authentication';
import { RetailRouter } from './retail';
import { Route } from 'react-router-dom';
import { RouteLoadingFallback } from 'routes/route-loading-fallback';
import { Routes } from './Routes';
import { TargetedBanner } from 'pages/components/targeted-banner';
import React, { lazy } from 'react';

const Login = lazy(() => import('pages/auth/login/login'));
const PasswordReset = lazy(
  () => import('pages/auth/password-reset/password-reset')
);
const SignUp = lazy(() => import('pages/auth/signup/signup'));

export const Router: React.FC = () => {
  return (
    <Routes>
      <Route element={<TargetedBanner />}>
        <Route path="/" element={<RequireAuthentication />}>
          <Route path="*" element={<PrimaryTemplate />}>
            <Route path="admin/*" element={<AdminRouter />} />
            <Route path="brand/*" element={<BrandRouter />} />
            <Route path="corporate/*" element={<CorporateRouter />} />
            <Route path="retail/*" element={<RetailRouter />} />
            <Route index element={<AuthenticatedIndexRoute />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/present/*" element={<PresentTemplate />}>
            <Route path="admin/*" element={<AdminPresentRouter />} />
          </Route>
        </Route>

        <Route
          path="/login"
          element={
            <RouteLoadingFallback>
              <Login />
            </RouteLoadingFallback>
          }
        />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/signup"
          element={
            <RouteLoadingFallback>
              <SignUp />
            </RouteLoadingFallback>
          }
        />
        <Route path="/help/shopify" element={<HelpShopify />} />
        <Route
          path="/password_reset"
          element={
            <RouteLoadingFallback>
              <PasswordReset />
            </RouteLoadingFallback>
          }
        />
      </Route>
    </Routes>
  );
};
