import {
  FeaturedIconSizes,
  FeaturedIconThemeProps,
} from './featured-icon.types';
import {
  StyleFunctionProps,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';

function getBorderWidth(size: FeaturedIconSizes) {
  switch (size) {
    case 'xs':
      return '0.125rem'; // 2px
    case 'sm':
      return '0.25rem'; // 4px
    case 'md':
      return '0.375rem'; // 6px
    case 'lg':
      return '0.5rem'; // 8px
    case 'xl':
      return '0.625rem'; // 10px
  }
}

const lightCircle = defineStyle(
  ({ colorScheme: c }: FeaturedIconThemeProps) => {
    return {
      backgroundColor: `${c}.100`,
      color: `${c}.600`,
      borderWidth: 0,
    };
  }
);

const lightCircleOutline = defineStyle(
  ({ colorScheme: c, size }: FeaturedIconThemeProps) => {
    return {
      backgroundColor: `${c}.100`,
      color: `${c}.600`,
      borderColor: `${c}.50`,
      borderWidth: getBorderWidth(size),
      borderStyle: 'solid',
    };
  }
);

const darkCircle = defineStyle(({ colorScheme: c }: FeaturedIconThemeProps) => {
  return {
    backgroundColor: `${c}.600`,
    color: 'base.white',
    borderWidth: 0,
  };
});

const darkCircleOutline = defineStyle(
  ({ colorScheme: c, size }: StyleFunctionProps) => {
    return {
      backgroundColor: `${c}.600`,
      color: 'base.white',
      borderColor: `${c}.700`,
      borderWidth: getBorderWidth(size),
      borderStyle: 'solid',
    };
  }
);

export const featuredIconTheme = defineStyleConfig({
  baseStyle: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  variants: {
    lightCircle,
    lightCircleOutline,
    darkCircle,
    darkCircleOutline,
  },
  sizes: {
    xs: {
      p: '0.5rem', // 8
      w: '6',
      h: '6',
    },
    sm: {
      p: '0.625rem', // 10
      w: '8',
      h: '8',
    },
    md: {
      p: '0.75rem', // 12
      w: '10',
      h: '10',
    },
    lg: {
      p: '0.875rem', // 14
      w: '12',
      h: '12',
    },
    xl: {
      p: '1rem', // 16
      w: '14',
      h: '14',
    },
  },
  defaultProps: {
    size: 'xs',
    variant: 'lightCircle',
  },
});
