import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const parts = ['container', 'definition', 'term'];

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle({
  details: {
    fontSize: 'lg',
    margin: '0',
  },
  term: {
    fontSize: 'lg',
    fontWeight: 500,
  },
});

export const descriptionListTheme = defineMultiStyleConfig({
  baseStyle,
});
