import { Logout } from 'pages/auth';
import { Navigate } from 'react-router-dom';
import { RouteLoadingFallback } from './route-loading-fallback';
import { useUserRoleGrouping } from 'hooks';
import React, { lazy } from 'react';

const BrandDashboard = lazy(
  () => import('pages/brand/dashboard/brand-dashboard')
);

export const AuthenticatedIndexRoute: React.FC = () => {
  const userRoleGrouping = useUserRoleGrouping();

  switch (userRoleGrouping) {
    case 'ADMIN':
      return <Navigate to="/admin" />;
    case 'BRAND':
      return (
        <RouteLoadingFallback>
          <BrandDashboard />
        </RouteLoadingFallback>
      );
    case 'CORPORATE':
      return <Navigate to="/corporate" />;
    case 'RETAIL':
      return <Navigate to="/retail" />;
    default:
      return <Logout />;
  }
};
