import styled from '@emotion/styled';

export const Anchor = styled.a`
  color: ${({ theme }) => theme.colorsDeprecated('link')};
  text-decoration: none;
  cursor: pointer;
  &:visited {
    color: ${({ theme }) => theme.colorsDeprecated('link', 0.5)};
  }
`;
