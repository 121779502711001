import { Navigate } from 'react-router-dom';
import { useUserRoles } from 'hooks';
import React, { lazy } from 'react';

const AdminOwnerDashboard = lazy(
  () => import('pages/admin/dashboards/admin-owner')
);

export const AdminIndexRoute: React.FC = () => {
  const { userRoles } = useUserRoles();

  if (userRoles.indexOf('Admin: Owners') > -1) {
    return <AdminOwnerDashboard />;
  }

  return <Navigate to="/admin/brands" state={{ replace: true }} />;
};

export default AdminIndexRoute;
