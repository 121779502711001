import { ToastMessage } from './toaster.types';
import { makeId } from 'utils';
import { useStateValue } from 'providers';
import { useToaster } from './use-toaster';
import React from 'react';

/**
 * Toaster listens for changes to the messages reducer and calls Chakra's
 * `toast()` method from the `useAddToast()` hook.
 *
 * Toasts will render inside an Alert in the bottom right of the App.
 *
 * This will support the Apps
 * current actions which dispatch to the messages reducer.
 *
 * @example
 * To invoke a Toast from Toaster:
 * ```tsx
 * dispatch(
 *   addMessage({
 *     ephemeral: 1000,
 *     message: 'This is a Toast',
 *     title: 'Success',
 *     type: 'success'
 *   })
 * );
 * ```
 * */
export const Toaster: React.FC = () => {
  const processedMessagesRef = React.useRef(new Set<ToastMessage>());

  const [
    {
      messages: { messages },
    },
  ] = useStateValue();
  const addToast = useToaster();

  React.useEffect(() => {
    messages.forEach((message) => {
      const toastMessage: ToastMessage = message;

      if (!toastMessage.id) {
        toastMessage.id = makeId(8);
      }

      if (!processedMessagesRef.current.has(toastMessage)) {
        processedMessagesRef.current.add(toastMessage);
        addToast(toastMessage);
      }
    });
  }, [addToast, messages]);

  return null;
};
