import React, { createContext, useState } from 'react';

interface AuthContextType {
  redirectPath: string | null;
  setRedirectPath: (path: string | null) => void;
}

const AuthenticationContext = createContext<AuthContextType | undefined>(
  undefined
);

export const AuthenticationProvider = ({ children }) => {
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  return (
    <AuthenticationContext.Provider value={{ redirectPath, setRedirectPath }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = () => {
  const context = React.useContext(AuthenticationContext);
  if (!context) {
    throw new Error(
      'useAuthenticationContext must be used within a AuthenticationProvider'
    );
  }
  return context;
};
