import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers([
    'buttonContainer',
    'container',
    'label',
    'selectContainer',
  ]);

const baseStyle = definePartsStyle(() => {
  return {
    buttonContainer: {
      alignItems: 'center',
      display: 'flex',
      gap: 2,
    },
    container: {
      display: 'flex',
      gap: 2,
      left: 0,
      position: 'sticky',
    },
    label: {
      color: 'text.secondary',
    },
    selectContainer: {
      alignItems: 'center',
      display: 'flex',
      gap: 2,
      justifyContent: 'space-between',
    },
  };
});

const sizes = {
  base: definePartsStyle(({ theme }) => {
    return {
      buttonContainer: {
        'button:nth-of-type(1)': {
          order: 1,
        },
        'button:nth-of-type(2)': {
          order: 2,
        },
        'button:nth-of-type(3)': {
          order: 4,
        },
        'button:nth-of-type(4)': {
          order: 5,
        },
        'div:first-of-type': {
          order: 3,
        },
      },
      container: {
        flexDirection: 'column',
        maxW: 'calc(100vw - 2rem)',
        px: 4,
        py: 3,
      },
      label: {
        flex: '2 0 auto',
        ...theme.textStyles.labelSmallRegular,
      },
      selectContainer: {
        order: 3,
      },
    };
  }),
  sm: definePartsStyle(({ theme }) => {
    return {
      buttonContainer: {
        order: 2,
        'button:nth-of-type(1)': {
          order: 2,
        },
        'button:nth-of-type(2)': {
          order: 3,
        },
        'button:nth-of-type(3)': {
          order: 4,
        },
        'button:nth-of-type(4)': {
          order: 5,
        },
        'div:first-of-type': {
          mr: 4,
          order: 1,
        },
      },
      container: {
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxW: 'calc(100vw - 3rem)',
        px: 4,
        py: 3,
      },
      label: {
        ...theme.textStyles.labelSmallRegular,
      },
      selectContainer: {
        flex: '0 1 10rem',
        order: 1,
      },
    };
  }),
  md: definePartsStyle(({ theme }) => {
    return {
      buttonContainer: {
        order: 2,
        'button:nth-of-type(1)': {
          order: 2,
        },
        'button:nth-of-type(2)': {
          order: 3,
        },
        'button:nth-of-type(3)': {
          order: 4,
        },
        'button:nth-of-type(4)': {
          order: 5,
        },
        'div:first-of-type': {
          mr: 4,
          order: 1,
        },
      },
      container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxW: 'calc(100vw - 9rem)',
        px: 6,
        py: 4,
      },
      label: {
        ...theme.textStyles.labelSmallRegular,
      },
      selectContainer: {
        order: 1,
      },
    };
  }),
};

export const paginationTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
});
