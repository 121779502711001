import { DesktopViewProps } from './screen-display.types';
import { useBreakpointValue } from '@chakra-ui/react';
import React from 'react';

/**
 * @deprecated
 * Wrapper component for Chakra's [useBreakpointValue()](https://chakra-ui.com/docs/hooks/use-breakpoint-value) hook.
 * Returns children when `useBreakpointValue()` returns `true` for `md` and above.
 * */
export const DesktopView: React.FC<DesktopViewProps> = ({ children }) => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  return <>{isDesktop ? children : null}</>;
};
