import { AbilityProvider } from './abilityProvider';
import { AuthenticationProvider } from './authentication-provider';
import { StateProvider } from './stateProvider';
import { ThemeProvider } from './themeProvider';
import { initialState as baseState, Reducer as reducers } from 'reducers';

export const GlobalProvider = ({ children, initialState = baseState }) => {
  return (
    <ThemeProvider>
      <StateProvider initialState={initialState} reducer={reducers}>
        <AuthenticationProvider>
          <AbilityProvider>{children}</AbilityProvider>
        </AuthenticationProvider>
      </StateProvider>
    </ThemeProvider>
  );
};
