import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { menuAnatomy as parts } from '@chakra-ui/anatomy';

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyleList = defineStyle({
  bg: 'base.white',
  borderRadius: 'lg',
  borderWidth: '1px',
  boxShadow: 'lg',
  color: 'inherit',
  minW: 48,
  py: 2,
  zIndex: 1,
});

const baseStyleItem = defineStyle({
  backgroundColor: 'base.white',
  borderWidth: 0,
  color: 'text.secondary',
  height: 10,
  transitionProperty: 'background',
  transitionDuration: 'ultra-fast',
  transitionTimingFunction: 'ease-in',
  outline: 'none',
  px: 4,
  py: '.625rem',
  _focus: {
    bg: 'primary.100',
  },
  _visited: {
    color: 'inherit',
  },
  _hover: {
    bg: 'primary.100',
    cursor: 'pointer',
  },
  _focusVisible: {
    outlineColor: 'primary.300',
    outlineStyle: 'solid',
    outlineWidth: '0.125rem',
  },
  _active: {
    bg: 'primary.300',
  },
  _expanded: {
    bg: 'neutral.100',
  },
  _disabled: {
    cursor: 'not-allowed',
    opacity: 0.4,
  },
});

const baseStyleGroupTitle = defineStyle({
  fontSize: 'md',
  fontWeight: 'semibold',
  mx: 4,
  my: 2,
});

const baseStyleCommand = defineStyle({
  opacity: 0.6,
});

const baseStyleDivider = defineStyle({
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'inherit',
  my: 2,
  opacity: 0.6,
});

const baseStyleButton = defineStyle({
  transitionDuration: 'normal',
  transitionProperty: 'common',
});

const baseStyle = definePartsStyle(() => ({
  button: baseStyleButton,
  command: baseStyleCommand,
  divider: baseStyleDivider,
  item: baseStyleItem,
  groupTitle: baseStyleGroupTitle,
  list: baseStyleList,
}));

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
});
