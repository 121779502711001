import { ErrorTemplate } from 'bam';
import { NotAuthorizedIllustration } from '../../components/images/illustrations/NotAuthorizedIllustration';
import { useStateValue } from 'providers';
import { useTranslation } from 'react-i18next';
import React from 'react';

export const NotAuthorized: React.FC = () => {
  const [{ session }] = useStateValue();
  const { t } = useTranslation('error');

  if (session.token) {
    return (
      <ErrorTemplate
        ctaUrl="/"
        image={<NotAuthorizedIllustration />}
        message={t('403.message')}
        subtitle={t('403.subtitle')}
        title={t('403.title')}
      />
    );
  }

  return (
    <ErrorTemplate
      ctaText={t('401.ctaText')}
      ctaUrl="/login"
      image={<NotAuthorizedIllustration />}
      message={t('401.message')}
      subtitle={t('401.subtitle')}
      title={t('401.title')}
    />
  );
};
