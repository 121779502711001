import { AppSideNavigation } from './components/app-side-navigation';
import { AppTopNavigation } from './components/app-top-navigation';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Show,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  ChevronLeftDouble,
  ChevronRightDouble,
  HelpCircle,
  Menu01,
  UserCircle,
} from '../../icons';
import { CloseButton } from '../../components/other/close-button';
import { IconButton } from '../../components/forms/icon-button';
import { LeapLogoBanner, LeapLogoIcon } from '../../logos';
import { Link } from '../../components/navigation/link';
import { Loading } from 'bam';
import { NavItem } from './components/nav-item/nav-item';
import { NavList } from './components/nav-list/nav-list';
import { Outlet, Link as ReactRouterLink } from 'react-router-dom';
import { SkipNavContent, SkipNavLink } from '@chakra-ui/skip-nav';
import { TemplateContent } from '../components/template-content';
import { closeSidebar, openSidebar } from 'actions';
import { useStateValue } from 'providers';
import { useTranslation } from 'react-i18next';
import { useUserRoleGrouping } from 'hooks/use-user-role-grouping';
import React from 'react';

/*
 * Used for all pages that have a top and side navigation.
 * */
export const PrimaryTemplate: React.FC = () => {
  const [
    {
      global: { loading, open_sidebar },
    },
    dispatch,
  ] = useStateValue();
  const { t } = useTranslation('primary-template');
  const userRoleType = useUserRoleGrouping();
  const currentBreakpoint = useBreakpointValue({
    base: 'base',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
  });

  const handleLogoClick = () => {
    if (currentBreakpoint === 'base' || currentBreakpoint === 'sm') {
      toggleSidebar();
    }
  };

  const toggleSidebar = () => {
    open_sidebar ? dispatch(closeSidebar()) : dispatch(openSidebar());
  };

  return (
    <>
      <Grid
        className="PrimaryTemplate-grid"
        gridTemplateRows={'3.5rem 1fr'}
        gridTemplateColumns={'min-content 23fr'}
        h="inherit"
        minW="20rem"
        templateAreas={`"sidebar header"
        "sidebar main"
      `}
        transition="grid-template-columns 1s"
      >
        <SkipNavLink>{t('subnav.skipToNav')}</SkipNavLink>

        {/* Header */}
        <GridItem
          area="header"
          as="header"
          bg="base.white"
          borderBottom="1px solid"
          borderBottomColor="neutral.300"
          className="PrimaryTemplate-header"
          position="sticky"
          px={{ base: 2, sm: 4, md: 8 }}
          top={0}
          zIndex="banner"
        >
          <Flex alignItems="center" h="100%">
            <IconButton
              aria-label="toggle sidebar"
              border="none"
              color="neutral.500"
              display={{ md: 'none' }}
              icon={<Menu01 />}
              iconBoxSize="6"
              mr="auto"
              onClick={toggleSidebar}
              size="sm"
              variant="secondary"
            />

            <AppTopNavigation />
          </Flex>
        </GridItem>

        {/* Sidebar */}
        <GridItem
          area="sidebar"
          bg="base.white"
          borderRight="1px solid"
          borderRightColor="neutral.300"
          className="PrimaryTemplate-sidebar"
          minH="100vh"
          position={{
            base: 'fixed',
            md: 'static',
          }}
          transitionDuration="slow"
          transitionProperty="width"
          transitionTimingFunction="ease-in-out"
          width={{
            base: open_sidebar ? 'calc(100vw - 4rem)' : 0,
            md: open_sidebar ? '17.5rem' : '5rem',
          }}
          zIndex="sidebar"
        >
          <Box
            h="100%"
            overflow={{ base: 'hidden', md: 'visible' }}
            position="fixed"
            width="inherit"
          >
            <Flex
              flexDir="column"
              height="inherit"
              overflowX="hidden"
              pb={4}
              pt={2}
              px={4}
            >
              <Link
                as={ReactRouterLink}
                display="block"
                mb={5}
                ml={2}
                mt={1}
                onClick={handleLogoClick}
                to="/"
              >
                {open_sidebar ? (
                  <LeapLogoBanner
                    color="primary.600"
                    width="8.75rem"
                    height="2rem"
                  />
                ) : (
                  <LeapLogoIcon color="primary.600" boxSize="8" />
                )}
              </Link>

              <Box flex="2" mb={3}>
                <AppSideNavigation />
              </Box>

              <Box mb={2}>
                <NavList>
                  <NavItem
                    icon={<HelpCircle />}
                    label={t('subnav.help')}
                    id="helpWidget"
                    navLinkAs="button"
                    to="#"
                  />

                  {userRoleType === 'BRAND' && (
                    <Show below="md">
                      <NavItem
                        borderTop="1px solid"
                        borderTopColor="neutral.200"
                        icon={<UserCircle />}
                        label={t('subnav.account')}
                        mb={2}
                        pt={1}
                        to="/brand/account"
                      />
                    </Show>
                  )}

                  <Show above="md">
                    <NavItem
                      aria-label={open_sidebar ? '' : t('subnav.collapse')}
                      icon={
                        open_sidebar ? (
                          <ChevronLeftDouble />
                        ) : (
                          <ChevronRightDouble />
                        )
                      }
                      label={open_sidebar ? t('subnav.collapse') : ''}
                      navLinkAs="button"
                      onClick={toggleSidebar}
                      to="#"
                    />
                  </Show>
                </NavList>
              </Box>
            </Flex>
          </Box>
        </GridItem>

        {/* Main Content */}
        <GridItem
          area="main"
          as="main"
          bg="neutral.25"
          className="PrimaryTemplate-main"
          maxW={{
            base: '100vw',
            md: `calc(100vw - ${open_sidebar ? 17.5 : 5}rem)`,
          }}
        >
          <SkipNavContent />

          <TemplateContent>
            <Outlet />
          </TemplateContent>
        </GridItem>

        {/* Mobile Sidebar Overlay */}
        <Box
          bg="base.overlayBackground"
          bottom={0}
          display={{
            base: 'block',
            md: 'none',
          }}
          h="100vh"
          left={0}
          onClick={toggleSidebar}
          position="fixed"
          right={0}
          top={0}
          visibility={open_sidebar ? 'visible' : 'hidden'}
          w="100vw"
          zIndex="overlay"
        >
          <CloseButton
            color="neutral.25"
            onClick={toggleSidebar}
            position="absolute"
            right={5}
            top={5}
          />
        </Box>
      </Grid>

      <Loading loading={loading} />
    </>
  );
};

PrimaryTemplate.displayName = 'PrimaryTemplate';
