import { AddBrandUserDesktop, AddBrandUserMobile } from './add-brand-user';
import { Bell01, Lightning02, LogOut01, User02, UserCircle } from 'bam/icons';
import {
  Box,
  DesktopView,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MobileView,
  Notification,
} from 'bam';
import { Can, useStateValue } from 'providers';
import { LeapLogoIcon } from 'bam/logos';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useFlags } from 'showroom/use-flags';
import { useTranslation } from 'react-i18next';
import { useUserRoleGrouping } from 'hooks/use-user-role-grouping';
import React from 'react';

export const AppTopNavigation: React.FC = () => {
  const userRoleType = useUserRoleGrouping();
  const { t } = useTranslation('primary-template');
  const [{ session }] = useStateValue();
  const flags = useFlags();

  return (
    <>
      <LeapLogoIcon
        display={{ md: 'none' }}
        boxSize="8"
        justifySelf="center"
        color="primary.600"
      />

      <Box
        color="text.primary"
        display="flex"
        gap="4"
        ml="auto"
        position="relative"
      >
        {userRoleType === 'BRAND' ? (
          <Can I="post" a="brand:users">
            <DesktopView>
              <AddBrandUserDesktop />
            </DesktopView>
          </Can>
        ) : null}

        <Menu>
          <MenuButton
            aria-label="Toggle user menu"
            as={IconButton}
            backgroundColor="base.white"
            borderColor="transparent"
            borderRadius="50%"
            borderWidth="2px"
            display="flex"
            height="8"
            icon={<UserCircle color="icon.primaryDark" />}
            iconBoxSize="8"
            minWidth="auto"
            size="sm"
            width="8"
            variant="secondary"
            _hover={{
              backgroundColor: 'base.white',
            }}
            _active={{
              backgroundColor: 'base.white',
            }}
          />

          <MenuList>
            <MenuItem
              icon={<User02 boxSize="5" />}
              as={ReactRouterLink}
              to={`/${userRoleType.toLowerCase()}/account`}
            >
              {t('userMenu.account')}
            </MenuItem>

            {(userRoleType === 'ADMIN' ||
              userRoleType === 'BRAND' ||
              userRoleType === 'RETAIL') &&
            flags.notifications ? (
              <MenuItem
                icon={<Bell01 boxSize="5" />}
                as={ReactRouterLink}
                to={`/${userRoleType.toLowerCase()}/notifications`}
              >
                {t('userMenu.notifications')}

                {session.unread_in_app && session.unread_in_app > 0 ? (
                  <Notification
                    aria-label={t('header.newNotifications')}
                    color="warning"
                    ml="2"
                    size="sm"
                  >
                    {session.unread_in_app}
                  </Notification>
                ) : null}
              </MenuItem>
            ) : null}

            {userRoleType === 'BRAND' ? (
              <>
                <MenuItem
                  icon={<Lightning02 boxSize="5" />}
                  as={ReactRouterLink}
                  to="/brand/profile"
                >
                  {t('userMenu.brandProfile')}
                </MenuItem>

                <Can I="post" a="brand:users">
                  <MobileView>
                    <AddBrandUserMobile />
                  </MobileView>
                </Can>
              </>
            ) : null}

            <MenuDivider />

            <MenuItem
              icon={<LogOut01 boxSize="5" />}
              as={ReactRouterLink}
              to="/logout"
            >
              {t('userMenu.logout')}
            </MenuItem>
          </MenuList>
        </Menu>

        {session.unread_in_app &&
        session.unread_in_app > 0 &&
        flags.notifications ? (
          <Box
            aria-label={t('header.newNotifications')}
            bgColor="warning.600"
            borderRadius="50%"
            height={4}
            position="absolute"
            right="-.25rem"
            top="-.25rem"
            width={4}
          />
        ) : null}
      </Box>
    </>
  );
};
