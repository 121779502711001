import { types } from 'actions';

type MessageType = 'success' | 'danger' | 'error' | 'warning' | 'info';

export interface Message {
  ephemeral?: number;
  id?: string | number; // same type as `ToastId` but importing that type here feels weird
  message?: string;
  title: string;
  type: MessageType;
}

interface MessagesState {
  messages: Array<Message>;
}

export const messagesInitialState: MessagesState = {
  messages: [],
};

export const defaultMessageTimeout = 5000;

export const messagesReducer = (
  state: MessagesState = messagesInitialState,
  action
): MessagesState => {
  switch (action.type) {
    case types.ADD_MESSAGE: {
      const messages = [...state.messages, action.data];
      return {
        messages,
      };
    }
    case types.REMOVE_MESSAGE: {
      return {
        messages: state.messages.filter((msg) => msg !== action.data),
      };
    }
    case types.REMOVE_ERRORS: {
      return {
        messages: state.messages.filter((msg) => msg.type !== 'error'),
      };
    }
    case types.LOADING:
    case types.REMOVE_ALL_MESSAGES: {
      return {
        ...messagesInitialState,
      };
    }
    default:
      return state;
  }
};
