import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import React from 'react';

export const CookieConsent: React.FC = () => {
  const [visible, setVisible] = React.useState(false);
  const { t } = useTranslation('bam', { keyPrefix: 'cookieConsent' });
  const cookieName = t('cookieName');

  const handleOnClick = () => {
    Cookies.set(cookieName, 'true', { expires: 365 });
    setVisible(false);
  };

  React.useEffect(() => {
    if (!Cookies.get(cookieName)) {
      setVisible(true);
    }
  }, [cookieName]);

  return (
    <>
      {visible ? (
        <Flex
          alignItems="center"
          bg="neutral.200"
          borderRadius="lg"
          bottom={4}
          gap={4}
          justifyContent="center"
          mx={4}
          position="fixed"
          px={6}
          py={4}
          width="calc(100vw - 2rem)"
          zIndex={99999}
        >
          <Box>
            <Heading as="h2" size="sm">
              {t('heading')}
            </Heading>

            <Text mb={0}>{t('text')}</Text>
          </Box>

          <Button onClick={handleOnClick}>{t('buttonLabel')}</Button>
        </Flex>
      ) : null}
    </>
  );
};
