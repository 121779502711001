import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthenticationContext, useStateValue } from 'providers';
import React from 'react';

export const RequireAuthentication: React.FC = () => {
  const { setRedirectPath } = useAuthenticationContext();
  const location = useLocation();
  const [{ session }] = useStateValue();
  const isAuthenticated = session.token;

  React.useEffect(() => {
    if (!isAuthenticated) {
      const redirectPath = `${location.pathname}${
        location.search ? `${location.search}` : ''
      }`;
      setRedirectPath(redirectPath);
    }
  }, [isAuthenticated, location.pathname, location.search, setRedirectPath]);

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};
